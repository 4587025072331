@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

html {
  font-family: "Cairo", sans-serif;
}

:focus-visible {
  outline: none !important;
}
